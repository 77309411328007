<template>
<PageHeader/>
	
  <div class="wrapper graybg top">
   <p>Молодежная площадка для знакомств. Регистрация в 3 шага, просто оставляешь фото, имя и вперед, к знакомству. Будущую пару можно описать и указать некоторые параметры для выбора. </p>
   <p>Площадка для знакомств. Регистрация в 3 шага, просто оставляешь фото, имя и вперед, к знакомству. Первые 10 красоток уже ждут тебя, они желают сегодня познакомиться. Будущую пару можно описать и указать некоторые параметры для выбора. Площадка для знакомств. Регистрация в 3 шага, просто оставляешь фото, имя и вперед, к знакомству.</p>
 </div>
 
 <div class="button_wrapper">
 <a href="#intro" class="button pink">Регистрация</a>
 <a href="#login" class="button pinkfill">Войти</a>
 
 </div>
 
</template>

<script>

export default {
  name: 'Welcome' 
}
</script>